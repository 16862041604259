<template id="optasetup">
  <b-container fluid>
    <h3>Role Weight List Setup</h3>
    <b-row align-v="center" class="mt-3 mb-3">
      <b-col cols="3">
        <b-form-select
          v-model="lista_sel_id"
          :options="liste"
          value-field="id"
          text-field="name"
          :disabled="new_lista || edit_lista"
        />
      </b-col>
      <b-col>
        <b-btn
          class="mr-2"
          variant="info"
          title="Edit list"
          @click="editLista"
          aria-controls="collapse2"
          v-if="lista_sel_id"
          ><b-icon icon="pencil"></b-icon
        ></b-btn>
        <b-btn
          class="mr-2"
          variant="warning"
          title="Copy list"
          @click="clonaLista"
          v-if="lista_sel_id"
          ><b-icon icon="files"></b-icon
        ></b-btn>
        <b-btn
          class="mr-2"
          variant="danger"
          title="Delete list"
          @click="eliminaLista"
          v-if="lista_sel_id"
          ><b-icon icon="dash"></b-icon
        ></b-btn>
        <b-btn
          class="mr-2"
          variant="primary"
          title="Add list"
          aria-controls="collapse"
          @click="newLista"
          ><b-icon icon="plus"></b-icon
        ></b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-collapse id="collapse" v-model="new_lista" class="mb-1">
          <b-row align-v="center" class="mt-2 mb-4">
            <b-col cols="3">
              <b-form-input
                v-model="lista.name"
                type="text"
                placeholder="Insert new list"
              ></b-form-input>
            </b-col>
            <b-col cols="2">
              <b-form-select
                v-model="lista.cluster_id"
                :options="clusters"
                value-field="id"
                text-field="name"
              />
            </b-col>
            <b-col cols="1" class="text-right"> From </b-col>
            <b-col cols="2">
              <b-form-datepicker
                v-model="lista.from"
                :locale="ita ? 'it' : 'en-US'"
                :dark="true"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="1" class="text-right"> To </b-col>
            <b-col cols="2">
              <b-form-datepicker
                v-model="lista.to"
                :locale="ita ? 'it' : 'en-US'"
                :dark="true"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="1">
              <b-button
                :variant="waiting_list ? 'warning' : 'primary'"
                @click="aggiungiLista"
                :disabled="waiting_list"
                >Save
                <b-icon
                  icon="arrow-repeat"
                  :animation="waiting_list ? 'spin' : ''"
                ></b-icon
              ></b-button>
            </b-col>
          </b-row>
        </b-collapse>
        <b-collapse id="collapse2" v-model="edit_lista" class="mb-1">
          <b-row align-v="center" class="mt-2 mb-4">
            <b-col cols="3">
              <b-form-input
                v-model="lista.name"
                type="text"
                placeholder="Name list"
              ></b-form-input>
            </b-col>
            <b-col cols="2">
              <b-form-select
                v-model="lista.cluster_id"
                :options="clusters"
                value-field="id"
                text-field="name"
              />
            </b-col>
            <b-col cols="1" class="text-right"> From </b-col>
            <b-col cols="2">
              <b-form-datepicker
                v-model="lista.from"
                :locale="ita ? 'it' : 'en-US'"
                :dark="true"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="1" class="text-right"> To </b-col>
            <b-col cols="2">
              <b-form-datepicker
                v-model="lista.to"
                :locale="ita ? 'it' : 'en-US'"
                :dark="true"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="1">
              <b-button
                :variant="waiting_list ? 'warning' : 'primary'"
                @click="modificaLista"
                :disabled="waiting_list"
                >Save
                <b-icon
                  icon="arrow-repeat"
                  :animation="waiting_list ? 'spin' : ''"
                ></b-icon
              ></b-button>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
    </b-row>
    <b-row v-if="lista_sel_id" class="mt-2">
      <b-col>
        <b-row class="mb-1">
          <b-col v-for="(group, key) in groups" :key="key" class="text-center">
            {{ group }}
            <b-row>
              <b-col> players ({{ playersByGroup(key).length }})</b-col>
            </b-row>
          </b-col>
        </b-row>
        <div style="max-height: 73vh; overflow-y: scroll">
          <b-card no-body class="mb-1">
            <b-row class="mt-1">
              <b-col
                v-for="(group, key) in groups"
                :key="key"
                class="text-center"
              >
                <b-row
                  class="mb-2"
                  align-v="center"
                  v-for="player in playersByGroup(key)"
                  :key="player.id"
                >
                  <b-col>
                    <b-row>
                      <b-col cols="3" class="text-center">
                        <b-img
                          width="50"
                          :src="
                            player.player.giocatore
                              ? player.player.giocatore.avatar_url
                              : '/assets/images/profile.png'
                          "
                        ></b-img>
                      </b-col>
                      <b-col class="text-left">
                        {{ player.player.player }}<br />
                        <small>{{
                          player.player.ruolo ? player.player.ruolo.ruolo : ""
                        }}</small>
                      </b-col>
                    </b-row>
                    <small
                      >{{ player.from | formatDate }} -
                      {{ player.to | formatDate }}</small
                    >
                    <b-row>
                      <b-col>
                        <b-button
                          size="sm"
                          class="my-2"
                          variant="secondary"
                          @click="openEditPlayer(player.id)"
                          ><b-icon icon="pencil"></b-icon
                        ></b-button>
                      </b-col>
                      <b-col>
                        <b-button
                          size="sm"
                          class="my-2"
                          variant="danger"
                          @click="deletePlayer(player.id)"
                          ><b-icon icon="trash"></b-icon
                        ></b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-button
                  size="sm"
                  class="my-2"
                  variant="primary"
                  @click="openSearchPlayer(key)"
                  ><b-icon icon="plus"></b-icon
                ></b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="lista_sel_id" class="mt-2">
      <b-col>
        <b-row class="mb-1">
          <b-col v-for="(group, key) in groups" :key="key" class="text-center">
            {{ group }}
            <b-row>
              <b-col cols="3" class="text-center">#</b-col>
              <b-col cols="5" class="text-center">skill</b-col>
              <b-col cols="4" class="text-center">w</b-col>
            </b-row>
          </b-col>
        </b-row>
        <div style="max-height: 73vh; overflow-y: scroll">
          <b-card no-body class="mb-1">
            <b-row class="mt-1">
              <b-col
                v-for="(group, key) in groups"
                :key="key"
                class="text-center"
              >
                <b-row
                  class="mb-2"
                  align-v="center"
                  v-for="(weight, index) in weightsByGroup(key)"
                  :key="index"
                >
                  <b-col cols="3" class="text-center">
                    <small>{{ index + 1 }}</small>
                  </b-col>
                  <b-col cols="5" class="text-center">
                    <div
                      style="
                        font-size: 0.65rem;
                        line-height: 1.5em;
                        height: 3em;
                        overflow: hidden;
                        cursor: pointer;
                      "
                      :title="getSkillById(weight.id).label"
                    >
                      {{ getSkillById(weight.id).label }}
                    </div>
                  </b-col>
                  <b-col cols="4" class="text-center">
                    <small>{{ weight.value }}</small>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <b-button class="my-2" variant="primary" @click="applyWeights"
          >Apply weights</b-button
        >
      </b-col>
    </b-row>

    <b-modal
      id="addPlayerModal"
      ref="addPlayerModal"
      title="Add player"
      ok-only
      ok-title="Close"
    >
      <b-row>
        <b-col>
          From
          <b-form-datepicker
            v-model="player.from"
            :locale="ita ? 'it' : 'en-US'"
            :dark="true"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          ></b-form-datepicker>
        </b-col>
        <b-col>
          To
          <b-form-datepicker
            v-model="player.to"
            :locale="ita ? 'it' : 'en-US'"
            :dark="true"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          ></b-form-datepicker>
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col>
          <b-input-group>
            <b-form-input
              type="text"
              v-model="search_name"
              placeholder="Search opta player"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="searchOptaPlayer"
                ><b-icon icon="search"></b-icon
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <div v-if="is_search">
        <h4>
          Waiting...
          <img :src="loading" style="width: 150px" />
        </h4>
      </div>
      <p v-if="!start_search_opta && !is_search && !opta_players.length">
        No player found!
      </p>
      <div v-if="!start_search_opta && !is_search && opta_players.length">
        <p>{{ opta_players.length }} players found!</p>
        <hr />
        <div style="height: 300px; overflow-y: scroll; overflow-x: hidden">
          <div class="row">
            <div
              v-for="opta_player in opta_players"
              :key="opta_player.id"
              class="col-6"
              style="border-bottom: 1px solid #343a40"
            >
              <b-row align-v="center" class="py-2">
                <b-col cols="2">
                  <b-form-radio
                    v-model="player.player_id"
                    name="opta-players"
                    :value="opta_player.id"
                  ></b-form-radio>
                </b-col>
                <b-col cols="10" style="font-size: 0.85rem">
                  <h6 style="display: inline">
                    {{ opta_player.lastName }} {{ opta_player.firstName }}
                  </h6>
                  <br />
                  <span class="text-muted">{{ opta_player.age }} years old</span
                  ><br />
                  <span class="text-muted">{{
                    opta_player.ruolo ? opta_player.ruolo.ruolo : ""
                  }}</span
                  ><br />
                  <span
                    class="text-muted"
                    v-if="opta_player.last_stat && opta_player.last_stat.team"
                  >
                    {{ opta_player.last_stat.team.name }} </span
                  ><br />
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <b-button
          v-if="player.player_id"
          size="sm"
          :variant="waiting ? 'warning' : 'primary'"
          @click="addPlayer"
          class="mt-3"
          :disabled="waiting"
          >Add player to list
          <b-icon
            icon="arrow-repeat"
            :animation="waiting ? 'spin' : ''"
          ></b-icon
        ></b-button>
      </div>
    </b-modal>
    <b-modal
      id="editPlayerModal"
      ref="editPlayerModal"
      title="Edit player"
      ok-only
      ok-title="Close"
    >
      <b-row>
        <b-col>
          From
          <b-form-datepicker
            v-model="player.from"
            :locale="ita ? 'it' : 'en-US'"
            :dark="true"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          ></b-form-datepicker>
        </b-col>
        <b-col>
          To
          <b-form-datepicker
            v-model="player.to"
            :locale="ita ? 'it' : 'en-US'"
            :dark="true"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          ></b-form-datepicker>
        </b-col>
      </b-row>
      <b-row v-if="player_sel" class="my-2" align-v="center">
        <b-col cols="3" class="text-center mr-2">
          <b-img
            :src="
              player_sel.player.giocatore
                ? player_sel.player.giocatore.avatar_url
                : '/assets/images/profile.png'
            "
          ></b-img>
        </b-col>
        <b-col class="text-left">
          <h4>{{ player_sel.player.player }}</h4>
          <p>
            {{ player_sel.player.ruolo ? player_sel.player.ruolo.ruolo : "" }}
          </p>
        </b-col>
      </b-row>

      <b-button
        v-if="player.player_id"
        size="sm"
        :variant="waiting ? 'warning' : 'primary'"
        @click="editPlayer"
        class="mt-3"
        :disabled="waiting"
        >Update<b-icon
          icon="arrow-repeat"
          :animation="waiting ? 'spin' : ''"
        ></b-icon
      ></b-button>
    </b-modal>
  </b-container>
</template>

<script>
import moment from "moment";
import loading from "@/assets/images/loading.gif";
export default {
  data: function () {
    return {
      ita: true,
      liste: [{ id: null, name: "-- Lists --" }],
      lista_sel_id: null,
      new_lista: false,
      edit_lista: false,
      lista: {
        name: null,
        from: null,
        to: null,
        cluster_id: null,
      },
      skills: [],
      groups: {
        0: "PORTIERI",
        1: "DIFENSORI CENTRALI",
        2: "ESTERNI",
        3: "CENTROCAMPISTI CENTRALI",
        4: "INTERNI DI CENTROCAMPO",
        5: "ATTACCANTI CENTRALI",
        6: "ATTACCANTI ESTERNI",
      },
      players: [],
      player: {
        player_id: null,
        from: null,
        to: null,
        role_group: null,
      },
      opta_players: [],
      search_name: null,
      start_search_opta: true,
      is_search: false,
      loading,
      waiting: false,
      player_sel: null,
      no_weight_skills_id: [1, 2, 3, 110, 111, 112, 113, 114, 115],
      clusters: [],
      waiting_list: false,
      cluster_ranges: [],
    };
  },

  components: {},

  created: function () {
    this.getSkills();
    this.getClusters();
    this.getListePesiRuoli();
  },

  methods: {
    getSkills() {
      this.$http.get("/opta/skills").then((response) => {
        this.skills = response.data;
      });
    },

    getClusters() {
      this.$http.get("/opta/clusters").then((response) => {
        this.clusters = response.data;
        this.clusters.unshift({ id: null, name: "-- Cluster --" });
      });
    },

    getListePesiRuoli() {
      this.$http.get("/opta/liste/pesi/ruoli").then((response) => {
        this.liste = response.data;
        this.liste.unshift({ id: null, name: "-- Lists --" });
      });
    },

    newLista() {
      if (!this.new_lista) {
        this.lista.name = null;
        this.lista.from = null;
        this.lista.to = null;
        this.lista.cluster_id = null;
      }
      this.new_lista = !this.new_lista;
    },

    editLista() {
      if (!this.edit_lista) {
        var lista = this.liste.find((item) => item.id === this.lista_sel_id);
        this.lista.name = lista.name;
        this.lista.from = lista.from;
        this.lista.to = lista.to;
        this.lista.cluster_id = lista.cluster_id;
      }
      this.edit_lista = !this.edit_lista;
    },

    aggiungiLista() {
      if (!this.lista.name || this.lista.name === "") {
        alert("Insert list name!");
        return;
      }
      if (!this.lista.cluster_id) {
        alert("Select a cluster!");
        return;
      }
      if (!this.lista.from || this.lista.from === "") {
        alert("Insert from date!");
        return;
      }
      if (!this.lista.to || this.lista.to === "") {
        alert("Insert to date!");
        return;
      }
      this.waiting_list = true;
      this.$http
        .post("/opta/add/lista/pesi/ruoli", this.lista)
        .then((response) => {
          this.new_lista = false;
          this.$store.commit("msgPush", {
            msg: "List added succesfully!",
            status: 1,
          });
          this.liste = response.data;
          this.liste.unshift({ id: null, name: "-- Lists --" });
          this.waiting_list = false;
        });
    },

    modificaLista() {
      if (!this.lista.name || this.lista.name === "") {
        alert("Insert list name!");
        return;
      }
      if (!this.lista.cluster_id) {
        alert("Select a cluster!");
        return;
      }
      if (!this.lista.from || this.lista.from === "") {
        alert("Insert from date!");
        return;
      }
      if (!this.lista.to || this.lista.to === "") {
        alert("Insert to date!");
        return;
      }
      this.waiting_list = true;
      this.$http
        .post("/opta/edit/lista/pesi/ruoli/" + this.lista_sel_id, this.lista)
        .then((response) => {
          this.edit_lista = false;
          this.$store.commit("msgPush", {
            msg: "List updated succesfully!",
            status: 1,
          });
          this.liste = response.data;
          this.liste.unshift({ id: null, name: "-- Lists --" });
          this.waiting_list = false;
        });
    },

    eliminaLista() {
      if (confirm("Are you sure you want to delete the selected list?")) {
        this.$http
          .get("/opta/delete/lista/pesi/ruoli/" + this.lista_sel_id)
          .then((response) => {
            this.$store.commit("msgPush", {
              msg: "List deleted succesfully!",
              status: 1,
            });
            this.lista_sel_id = null;
            this.liste = response.data;
            this.liste.unshift({ id: null, name: "-- Lists --" });
          });
      }
    },

    clonaLista() {
      if (confirm("Are you sure you want to copy the selected list?")) {
        this.$http
          .get("/opta/clone/lista/pesi/ruoli/" + this.lista_sel_id)
          .then((response) => {
            this.$store.commit("msgPush", {
              msg: "List copied succesfully!",
              status: 1,
            });
            this.liste = response.data;
            this.liste.unshift({ id: null, name: "-- Lists --" });
          });
      }
    },

    openSearchPlayer(group) {
      this.search_name = null;
      this.player.player_id = null;
      this.player.from = this.liste.find(
        (item) => item.id === this.lista_sel_id
      ).from;
      this.player.to = this.liste.find(
        (item) => item.id === this.lista_sel_id
      ).to;
      this.player.role_group = group;
      this.opta_players = [];
      this.$refs.addPlayerModal.show();
    },

    searchOptaPlayer: function () {
      if (this.search_name != "") {
        this.opta_players = [];
        this.is_search = true;
        this.$http
          .get("/opta/search/player/" + this.search_name)
          .then((response) => {
            this.opta_players = response.data;
            this.start_search_opta = false;
            this.is_search = false;
          });
      }
    },

    addPlayer() {
      if (!this.player.from || this.player.from === "") {
        alert("Insert from date!");
        return;
      }
      if (!this.player.to || this.player.to === "") {
        alert("Insert to date!");
        return;
      }
      this.waiting = true;
      this.$http
        .post(
          "/opta/add/player/lista/pesi/ruoli/" + this.lista_sel_id,
          this.player
        )
        .then((response) => {
          if (response.data && response.data.error) {
            this.$store.commit("msgPush", {
              msg: response.data.error,
              status: 0,
            });
          } else {
            this.$store.commit("msgPush", {
              msg: "Player added succesfully!",
              status: 1,
            });
            this.players.push(response.data);
          }
          this.waiting = false;
          this.$refs.addPlayerModal.hide();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deletePlayer(id) {
      if (confirm("Are you sure you want to remove the player from list?")) {
        this.$http
          .get("/opta/delete/player/lista/pesi/ruoli/" + id)
          .then((response) => {
            this.$store.commit("msgPush", {
              msg: "Player removed succesfully!",
              status: 1,
            });
            var index = this.players.findIndex((item) => item.id == id);
            this.players.splice(index, 1);
          });
      }
    },

    openEditPlayer(id) {
      var player = this.players.find((item) => item.id == id);
      this.player_sel = player;
      this.player.player_id = player.id;
      this.player.from = player.from;
      this.player.to = player.to;
      this.player.role_group = player.role_group;
      this.$refs.editPlayerModal.show();
    },

    editPlayer() {
      if (!this.player.from || this.player.from === "") {
        alert("Insert from date!");
        return;
      }
      if (!this.player.to || this.player.to === "") {
        alert("Insert to date!");
        return;
      }
      this.waiting = true;
      this.$http
        .post(
          "/opta/edit/player/lista/pesi/ruoli/" + this.player.player_id,
          this.player
        )
        .then((response) => {
          if (response.data && response.data.error) {
            this.$store.commit("msgPush", {
              msg: response.data.error,
              status: 0,
            });
          } else {
            this.$store.commit("msgPush", {
              msg: "Player updated succesfully!",
              status: 1,
            });
            var index = this.players.findIndex(
              (item) => item.id == this.player.player_id
            );
            this.players.splice(index, 1, response.data);
          }
          this.waiting = false;
          this.$refs.editPlayerModal.hide();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    playersByGroup(group) {
      return this.players.filter((item) => item.role_group == group);
    },

    weightsByGroup(group) {
      var pesiRuoli = this.pesiRuoliGruppi[group];
      var array_weight = [];
      for (var key in pesiRuoli) {
        if (!this.no_weight_skills_id.includes(parseInt(key))) {
          var oject = { id: key, value: pesiRuoli[key] };
          array_weight.push(oject);
        }
      }
      array_weight.sort(function (a, b) {
        return b.value - a.value;
      });
      return array_weight;
    },

    getSkillById(id) {
      return this.skills.find((skill) => skill.id == id);
    },

    applyWeights() {
      if (confirm("Are you sure you want to apply this weights ?")) {
        this.$http
          .post("/opta/apply/weights", this.pesiRuoliGruppi)
          .then((response) => {
            this.$store.commit("msgPush", {
              msg: "Weights apply succesfully!",
              status: 1,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },

  watch: {
    lista_sel_id: function () {
      if (this.lista_sel_id) {
        this.cluster_ranges = [];
        this.$http
          .get("/opta/cluster/ranges/lista/pesi/ruoli/" + this.lista_sel_id)
          .then((response) => {
            this.cluster_ranges = response.data;
          });

        this.players = [];
        this.$http
          .get("/opta/players/lista/pesi/ruoli/" + this.lista_sel_id)
          .then((response) => {
            this.players = response.data;
          });
      }
    },
  },

  computed: {
    /*  pesiRuoliGruppi: function () {
      var mediaSkillsGruppi = {};
      for (var group in this.groups) {
        var mediaSkills = {};
        var array_players = this.playersByGroup(group);
        var filtered_skills = this.skills.filter(
          (item) => !this.no_weight_skills_id.includes(item.id)
        );
        for (var i in filtered_skills) {
          var skill = filtered_skills[i];
          var array_skills = [];
          for (var j in array_players) {
            var player = array_players[j];
            var stat = player.skills.find((item) => item.skill_id == skill.id);
            if (typeof stat !== "undefined") {
              array_skills.push(stat.media);
            }
          }
          var media = 0;
          if (array_skills.length > 0) {
            array_skills.sort(function (a, b) {
              return b - a;
            });
            var max = array_skills[0];
            var min = array_skills[array_skills.length - 1];

            var array_skills_norm = [];
            for (var j in array_skills) {
              if (max > min) {
                var norm = ((array_skills[j] - min) / (max - min)) * 100;
                array_skills_norm.push(norm);
              } else {
                array_skills_norm.push(0);
              }
            }
            for (var j in array_skills_norm) {
              media += array_skills_norm[j];
            }
            media = media / array_skills_norm.length;
          }
          mediaSkills[skill.id] = media;
        }
        mediaSkillsGruppi[group] = mediaSkills;
      }
      var pesiRuoliGruppi = {};
      for (var group in this.groups) {
        if (mediaSkillsGruppi.hasOwnProperty(group)) {
          var pesiSkills = {};
          var mediaSkill = mediaSkillsGruppi[group];
          const array_skills = Object.values(mediaSkill);
          array_skills.sort(function (a, b) {
            return b - a;
          });

          var peso = 1;
          var max = array_skills[0];
          var min = array_skills[array_skills.length - 1];

          for (var key in mediaSkill) {
            if (max > min) {
              peso = (mediaSkill[key] - min) / (max - min);
              peso = Math.round(peso * 1000) / 1000;
            }
            pesiSkills[key] = peso;
          }
          for (var i in this.no_weight_skills_id) {
            var skill_id = this.no_weight_skills_id[i];
            pesiSkills[skill_id] = 0;
          }
          pesiRuoliGruppi[group] = pesiSkills;
        }
      }
      return pesiRuoliGruppi;
    }, */

    pesiRuoliGruppi: function () {
      var pesiRuoliGruppi = {};
      var filtered_skills = this.skills.filter(
        (item) => !this.no_weight_skills_id.includes(item.id)
      );
      if (this.cluster_ranges.length == 0) {
        for (var group in this.groups) {
          var pesiSkills = {};
          for (var i in filtered_skills) {
            var skill = filtered_skills[i];
            pesiSkills[skill.id] = 1;
          }
          for (var i in this.no_weight_skills_id) {
            var skill_id = this.no_weight_skills_id[i];
            pesiSkills[skill_id] = 0;
          }
          pesiRuoliGruppi[group] = pesiSkills;
        }
        return pesiRuoliGruppi;
      }
      for (var group in this.groups) {
        var array_players = this.playersByGroup(group);
        var pesiSkills = {};
        for (var i in filtered_skills) {
          var skill = filtered_skills[i];
          var range = null;
          var obj_range = this.cluster_ranges.find(
            (item) => item.skill_id == skill.id && item.group == group
          );
          if (obj_range) {
            range = JSON.parse(obj_range.range);
          }
          if (range) {
            var index_players = [];
            for (var j in array_players) {
              var player = array_players[j];
              var stat = player.skills.find(
                (item) => item.skill_id == skill.id
              );
              if (typeof stat !== "undefined") {
                var index = -1;
                var value = stat.media;

                for (var x in range) {
                  if (
                    range[x].from == range[x].to && skill.reverse
                      ? value >= range[x].from && value <= range[x].to
                      : value <= range[x].from && value >= range[x].to
                  ) {
                    /*console.log('range[x].from == range[x].to ' + skill.id);
                    console.log('range[x].from: ' + range[x].from);
                    console.log('range[x].to: ' + range[x].to);
                    console.log('value: ' + value);
                    console.log('index: ' + x);*/
                    index = x;
                  } else if (
                    range[x].from != range[x].to &&
                    ((x < range.length - 1 && skill.reverse
                      ? value >= range[x].from && value < range[x].to
                      : value <= range[x].from && value > range[x].to) ||
                      (x == range.length - 1 && skill.reverse
                        ? value >= range[x].from && value <= range[x].to
                        : value <= range[x].from && value >= range[x].to))
                  ) {
                    /*console.log('range[x].from != range[x].to' + skill.id);
                    console.log('range[x].from: ' + range[x].from);
                    console.log('range[x].to: ' + range[x].to);
                    console.log('value: ' + value);
                    console.log('index: ' + x); */
                    index = x;
                  } else if (
                    !range[x].hasOwnProperty("to") &&
                    (skill.reverse
                      ? value >= range[x].from
                      : value <= range[x].from)
                  ) {
                    index = x;
                  }
                }
                if (index != -1) {
                  index_players.push(index);
                }
              }
            }
            if (index_players.length > 0) {
              var count = 0;
              for (var x in index_players) {
                count += parseInt(index_players[x]);
              }
              var media_index = Math.round(count / index_players.length);
              var peso = (10 - media_index) / 10;
              pesiSkills[skill.id] = peso;
            }
          }
          if (!pesiSkills[skill.id]) {
            pesiSkills[skill.id] = 0;
          }
        }
        for (var i in this.no_weight_skills_id) {
          var skill_id = this.no_weight_skills_id[i];
          pesiSkills[skill_id] = 0;
        }
        pesiRuoliGruppi[group] = pesiSkills;
      }
      return pesiRuoliGruppi;
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
